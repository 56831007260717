export type BrowserType = "chrome"|"safari"

let browser: BrowserType = null;

const agent = navigator.userAgent.toLowerCase();
if (agent.indexOf("safari") !== -1) {
  if (agent.indexOf("chrome") > -1) {
    browser = "chrome";
  } else {
    browser = "safari";
  }
}

export const browserName = browser;

import { observable, action, makeAutoObservable } from "mobx";
import { createContext } from "react";

class AppService {
  @observable navigationOpened = false;

  public history = null;

  constructor() {
    makeAutoObservable(this);
  }

  public setHistory = (history: any) => {
    this.history = history;
  }

  @action public toggleNavigationBar = () => {
    this.navigationOpened = !this.navigationOpened;
  }

  @action public closeNavigationBar = () => {
    this.navigationOpened = false;
  }
}

export const AppServiceInstance = new AppService();

export const AppServiceContext = createContext(AppServiceInstance);

import { format } from "date-fns";

export const timestampToHoursAndMinutes = (timestamp: number): string => {
  if (!timestamp) {
    return "--:--";
  }
  return format(timestamp, "HH:mm");
};

export const getGameStartDate = (startTimestamp: number): string => {
  return format(startTimestamp, "MM_dd_yyyy");
};

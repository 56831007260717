import { observer } from "mobx-react";
import { Route, RouteComponentProps } from "react-router-dom";

interface IProps {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

export const CustomRoute = observer(({ Component, path, exact }: IProps) => {
  // any guards here
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => <Component {...props} />}
    />
  );
});
